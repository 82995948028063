export enum UserRole {
  Unknown = 0,
  Admin = 1,
  AirportAdmin = 2,
  AirportOfficer = 3,
}

export type User = {
  id: string
  username: string
  email: string
  createdAt: Date
  updatedAt: Date
  hashedPassword?: string
  roles: UserRole[]
  firstName: string
  lastName: string
}

export type CreateUserRequest = {
  username: string
  email: string
  password?: string
  roles: UserRole[]
  firstName: string
  lastName: string
  airportId?: string
}

export type UpdateUserRequest = {
  id: string
  username: string
  email: string
  password?: string
  roles: UserRole[]
  firstName: string
  lastName: string
  airportId?: string
}

export type Visitor = {
  id: string
  email: string
  image: string
  dob: string
  gender: string
  phone: string
  company: string
  job: string
  hashedPassword: string
  firstName: string
  lastName: string
  verified: boolean
  status: string
  auth0Id: string
  createdAt: Date
  updatedAt: Date
}

export type CreateVisitorRequest = {
  email: string
  image?: string
  dob?: string
  gender?: string
  phone?: string
  company?: string
  job?: string
  password: string
  firstName: string
  lastName: string
}

export type UpdateVisitorRequest = {
  email?: string
  image?: string
  dob?: string
  gender?: string
  phone?: string
  company?: string
  job?: string
  firstName?: string
  lastName?: string
  verified?: boolean
  status?: number
}

export type CreateAirportRequest = {
  name: string
  timezone: string
}

export type UpdateAirportRequest = {
  name?: string
  logo?: string
  code?: string
  timezone?: string
}

export enum VisitorPassStatus {
  Unknown = 0,
  Requested = 1,
  Approved = 2,
  Declined = 3,
  Expired = 4,
  Cancelled = 5,
  Printed = 6,
}

export enum SponsorStatus {
  Unknown = 0,
  Pending = 1,
  Approved = 2,
  Declined = 3,
}

export type CreateSponsorRequest = {
  asicId: string
  sponsorName: string
  sponsorEmail: string
  sponsorCompany: string
  sponsorMobile: string
  expiryMonth: string
  expiryYear: string
}

export type UpdateSponsorRequest = {
  asicId?: string
  sponsorName?: string
  sponsorEmail?: string
  sponsorCompany?: string
  sponsorMobile?: string
  expiryMonth?: string
  expiryYear?: string
}

export interface ID {
  idType: "Unknown" | "Passport" | "Driver's License"
  idNumber: string
}
