import { Dialog, Switch, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  CheckIcon,
  ExclamationIcon,
  MailIcon,
  MinusIcon,
  PlusIcon,
  PrinterIcon,
  SaveIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { UserAddIcon } from "@heroicons/react/solid";
import { addDays, addMonths } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../components/Sidebar/Sidebar";
import VisitorPassLogs from "../../../components/VisitorPassLogs/VisitorPassLogs";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { classNames, getMonthInText } from "../../../utils/helpers/misc";
import { ID, UserRole } from "../../../utils/types";
import moment from "moment-timezone";

export default function VisitorPassRequest() {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [printerError, setPrinterError] = useState<boolean>(false);
  const [openDeleteVisitorPassModal, setOpenDeleteVisitorPassModal] =
    useState<boolean>(false);
  const [invalidForm, setInvalidForm] = useState<boolean>(true);
  const [sponsorApprovedSuccess, setSponsorApprovedSuccess] =
    useState<boolean>(false);
  const [sponsorChangedSuccess, setSponsorChangedSuccess] =
    useState<boolean>(false);
  const [sponsorResendSuccess, setSponsorResendSuccess] =
    useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [approveSuccess, setApproveSuccess] = useState<boolean>(false);
  const [rejectSuccess, setRejectSuccess] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<string>("");
  const [printSuccess, setPrintSuccess] = useState<boolean>(false);
  const [cancelSuccess, setCancelSuccess] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>("");
  const [visitorPassRequest, setVisitorPassRequest] = useState<any>();
  const [vpNumber, setVPNumber] = useState<string>("VPUNKNOWN");
  const [facePhoto, setFacePhoto] = useState<string>("");
  const [idPhotoFront, setIdPhotoFront] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [ids, setIds] = useState<ID[]>([]);
  const [sponsorName, setSponsorName] = useState<string>("");
  const [sponsorAsicId, setSponsorAsicId] = useState<string>("");
  const [sponsorCompany, setSponsorCompany] = useState<string>("");
  const [initialSponsorEmail, setInitialSponsorEmail] = useState<string>("");
  const [initialSponsorMobileNumber, setInitialSponsorMobileNumber] =
    useState<string>("");
  const [sponsorFacePhoto, setSponsorFacePhoto] = useState<string>("");
  const [sponsorIdPhotoFront, setSponsorIdPhotoFront] = useState<string>("");
  const [sponsorEmail, setSponsorEmail] = useState<string>("");
  const [sponsorMobileNumber, setSponsorMobileNumber] = useState<string>("");
  const [sponsorStatus, setSponsorStatus] = useState<number>(0);
  const [sponsorAsicExpiryMonth, setSponsorAsicExpiryMonth] =
    useState<string>("1");
  const [sponsorAsicExpiryYear, setSponsorAsicExpiryYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const [operationalNeed, setOperationalNeed] = useState<string>("");
  const [airport, setAirport] = useState<any>();
  const [printers, setPrinters] = useState<any[]>([]);
  const [printerId, setPrinterId] = useState<string>("");
  const [rangeType, setRangeType] = useState<string>("24 Hours");
  const [startTime, setStartTime] = useState<string>(
    moment
      .utc(new Date().toISOString())
      .tz(moment.tz.guess())
      .format("YYYY-MM-DDTHH:mm")
  );
  const [endTime, setEndTime] = useState<string>(
    moment
      .utc(new Date().toISOString())
      .tz(moment.tz.guess())
      .format("YYYY-MM-DDTHH:mm")
  );
  const [status, setStatus] = useState<number>(0);
  const [visitorPassLogs, setVisitorPassLogs] = useState<any[]>([]);
  const [openRejectPassModal, setOpenRejectPassModal] =
    useState<boolean>(false);
  const [openCancelPassModal, setOpenCancelPassModal] =
    useState<boolean>(false);
  const [openPrintPassModal, setOpenPrintPassModal] = useState<boolean>(false);
  const [startPassAtOriginalStartTime, setStartPassAtOriginalStartTime] =
    useState<boolean>(false);
  const [airportOfficerRole, setAirportOfficerRole] = useState<boolean>(true);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertExpanded, setAlertExpanded] = useState<boolean>(false);
  const [visitorBlocked, setVisitorBlocked] = useState<boolean>(false);
  const browserPrint = new ZebraBrowserPrintWrapper();

  useEffect(() => {
    // Get vpRequest from database
    const visitorPassRequestId = params.visitorPassRequestId;
    if (visitorPassRequestId) {
      fetchVisitorPass(visitorPassRequestId);
      fetchPrinters();
    } else {
      setError(true);
    }
  }, []);

  const fetchPrinters = () => {
    browserPrint
      .getAvailablePrinters()
      .then((printers) => {
        setPrinters(printers);

        if (!printerId) {
          setPrinterId(printers[0].uid);
        }
      })
      .catch((error) =>
        console.error("Error in browserPrint.getAvailablePrinters - ", error)
      );
  };

  useEffect(() => {
    if (
      auth.user &&
      !auth.loading &&
      auth.user.roles.includes(UserRole.AirportOfficer)
    ) {
      setAirportOfficerRole(true);
    } else {
      setAirportOfficerRole(false);
    }
  }, [auth.loading]);

  useEffect(() => {
    const startTimeDate = new Date(
      new Date(startTime).getTime() -
        new Date(startTime).getTimezoneOffset() * 60000
    );

    if (rangeType === "24 Hours") {
      setEndTime(addDays(startTimeDate, 1).toISOString().slice(0, -1));
    } else if (rangeType === "1 Week") {
      setEndTime(addDays(startTimeDate, 7).toISOString().slice(0, -1));
    } else if (rangeType === "1 Month") {
      setEndTime(addMonths(startTimeDate, 1).toISOString().slice(0, -1));
    }
  }, [startTime, rangeType]);

  useEffect(() => {
    if (startPassAtOriginalStartTime) {
      if (visitorPassRequest) {
        setStartTime(
          visitorPassRequest
            ? visitorPassRequest.startTime.substring(0, 16) ?? ""
            : ""
        );
      }
    } else {
      setStartTime(
        moment
          .utc(new Date().toISOString())
          .tz(moment.tz.guess())
          .format("YYYY-MM-DDTHH:mm")
      );
    }
  }, [startPassAtOriginalStartTime]);

  useEffect(() => {
    setInvalidForm(
      !facePhoto ||
        !idPhotoFront ||
        !firstName ||
        !lastName ||
        !email ||
        !mobileNumber ||
        !company ||
        !operationalNeed ||
        !sponsorName ||
        !sponsorAsicId ||
        !sponsorAsicExpiryMonth ||
        !sponsorAsicExpiryYear ||
        !sponsorCompany ||
        (!sponsorEmail && !sponsorMobileNumber) ||
        ids.length === 0 ||
        !rangeType ||
        !startTime ||
        !endTime
    );
  }, [
    facePhoto,
    idPhotoFront,
    firstName,
    lastName,
    email,
    mobileNumber,
    company,
    operationalNeed,
    sponsorName,
    sponsorAsicId,
    sponsorAsicExpiryMonth,
    sponsorAsicExpiryYear,
    sponsorCompany,
    sponsorEmail,
    sponsorMobileNumber,
    ids,
    rangeType,
    startTime,
    endTime,
  ]);

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  const fetchVisitorPass = async (visitorPassRequestId: string) => {
    try {
      const visitorPass = await api.getVisitorPassById(visitorPassRequestId);
      if (visitorPass.status === 200 && visitorPass.data) {
        const vpRequest = visitorPass.data;
        setVisitorPassRequest(vpRequest);
        setVPNumber(vpRequest.vpNumber ?? "VPUNKNOWN");
        setEmail(vpRequest.email ?? "");
        setFirstName(vpRequest.firstName ?? "");
        setLastName(vpRequest.lastName ?? "");
        setCompany(vpRequest.company ?? "");
        setMobileNumber(vpRequest.mobileNumber ?? "");
        setIds(vpRequest.ids ?? "");
        setSponsorName(vpRequest.sponsorName ?? "");
        setSponsorAsicId(vpRequest.sponsorAsicId ?? "");
        setSponsorCompany(vpRequest.sponsorCompany ?? "");
        setInitialSponsorEmail(vpRequest.sponsorEmail ?? "");
        setInitialSponsorMobileNumber(vpRequest.sponsorMobileNumber ?? "");

        setSponsorEmail(vpRequest.sponsorEmail ?? "");
        setSponsorMobileNumber(vpRequest.sponsorMobileNumber ?? "");
        setSponsorStatus(vpRequest.sponsorStatus);
        setSponsorAsicExpiryMonth(vpRequest.sponsorAsicExpiryMonth ?? "");
        setSponsorAsicExpiryYear(vpRequest.sponsorAsicExpiryYear ?? "");
        setOperationalNeed(vpRequest.operationalNeed ?? "");
        setAirport(vpRequest.airport);
        setRejectReason(vpRequest.rejectReason ?? "");
        setCancelReason(vpRequest.cancelReason ?? "");
        setStartTime(vpRequest.startTime.substring(0, 16) ?? "");
        setRangeType(vpRequest.rangeType ?? "");
        setEndTime(vpRequest.endTime.substring(0, 16) ?? "");
        setStatus(vpRequest.status ?? 0);
        setVisitorPassLogs(vpRequest.logs ?? []);
        setAlertMessage(vpRequest.requestedBy.alertMessage ?? "");

        if (vpRequest.requestedBy.status === 3) {
          setVisitorBlocked(true);
        }

        setLoading(false);

        if (vpRequest.facePhoto) {
          api
            .getVisitorPassFileByPath(vpRequest.id, vpRequest.facePhoto)
            .then((response) => {
              setFacePhoto(response.data.url);
            })
            .catch((error) => {
              console.error("Error in getVisitorPassFileByPath - ", error);
            });
        }

        if (vpRequest.idPhotoFront) {
          api
            .getVisitorPassFileByPath(vpRequest.id, vpRequest.idPhotoFront)
            .then((response) => {
              setIdPhotoFront(response.data.url);
            })
            .catch((error) => {
              console.error("Error in getVisitorPassFileByPath - ", error);
            });
        }

        if (vpRequest.sponsorFacePhoto) {
          api
            .getVisitorPassFileByPath(vpRequest.id, vpRequest.sponsorFacePhoto)
            .then((response) => {
              setSponsorFacePhoto(response.data.url);
            })
            .catch((error) => {
              console.error("Error in getVisitorPassFileByPath - ", error);
            });
        }

        if (vpRequest.sponsorIdPhotoFront) {
          api
            .getVisitorPassFileByPath(
              vpRequest.id,
              vpRequest.sponsorIdPhotoFront
            )
            .then((response) => {
              setSponsorIdPhotoFront(response.data.url);
            })
            .catch((error) => {
              console.error("Error in getVisitorPassFileByPath - ", error);
            });
        }
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching visitor pass request with id ${visitorPassRequestId}`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const updateVisitorPass = async () => {
    setLoading(true);
    try {
      await api.updateVisitorPass(params.visitorPassRequestId, {
        firstName,
        lastName,
        email,
        mobileNumber,
        company,
        ids,
        sponsorAsicId,
        sponsorName,
        sponsorAsicExpiryMonth,
        sponsorAsicExpiryYear,
        sponsorCompany,
        sponsorEmail,
        sponsorMobileNumber,
        operationalNeed,
        rangeType,
        startTime,
        endTime,
      });

      if (
        initialSponsorEmail !== sponsorEmail ||
        initialSponsorMobileNumber !== sponsorMobileNumber
      ) {
        setSponsorChangedSuccess(true);
      }

      setUpdateSuccess(true);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
      setTimeout(() => setUpdateSuccess(false), 3000);
    } catch (error) {
      console.error("Error occurred while updating visitor pass - ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const resendSponsorVerificationRequest = async () => {
    setLoading(true);
    try {
      await api.resendSponsorVerificationRequest(params.visitorPassRequestId!);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
      setSponsorResendSuccess(true);
    } catch (error) {
      console.error(
        "Error occurred while resending sponsor verification request - ",
        error
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteVisitorPass = async () => {
    setLoading(true);
    try {
      await api.deleteVisitorPassById(params.visitorPassRequestId);
      navigate("/visitor-pass-requests");
    } catch (error) {
      console.error("Error occurred while deleting visitor pass - ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const approveVpRequest = async () => {
    setLoading(true);
    try {
      await api.approveVisitorPassById(params.visitorPassRequestId ?? "");
      setApproveSuccess(true);
      setTimeout(() => setApproveSuccess(false), 3000);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
    } catch (error) {
      console.error(
        `Error occurred while approving visitor pass request with id ${params.visitorPassRequestId}`,
        error
      );
      setError(true);
    }
    setLoading(false);
  };

  const rejectVpRequest = async () => {
    setLoading(true);
    setOpenRejectPassModal(false);
    try {
      await api.rejectVisitorPassById(
        params.visitorPassRequestId ?? "",
        rejectReason
      );
      setRejectSuccess(true);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
      setTimeout(() => setRejectSuccess(false), 3000);
    } catch (error) {
      console.error(
        `Error occurred while rejecting visitor pass request with id ${params.visitorPassRequestId}`,
        error
      );
      setError(true);
    }
    setLoading(false);
  };

  const cancelVpRequest = async () => {
    setLoading(true);
    setOpenCancelPassModal(false);
    try {
      await api.cancelVisitorPassById(
        params.visitorPassRequestId ?? "",
        cancelReason
      );
      setCancelSuccess(true);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
      setTimeout(() => setCancelSuccess(false), 3000);
    } catch (error) {
      console.error(
        `Error occurred while cancelling visitor pass request with id ${params.visitorPassRequestId}`,
        error
      );
      setError(true);
    }
    setLoading(false);
  };

  const printVpRequest = async () => {
    setLoading(true);
    setOpenPrintPassModal(false);
    try {
      if (printers.length > 0) {
        // Set the printer
        browserPrint.setPrinter(printers[0]);

        // Check printer status
        const printerStatus = await browserPrint.checkPrinterStatus();

        // Check if the printer is ready
        if (printerStatus.isReadyToPrint) {
          // ZPL script to print a simple barcode
          // Line 503 uses a double backslash next to 5c to represent a literal backslash
          const zpl = `^XA
~TA000
~JSN
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
~SD15
^JUS
^LRN
^CI27
^PA0,1,1,0
^XZ
^XA
^MMT
^PW432
^LL687
^LS0
^FPH,8^FT417,273^A0I,39,43^FB417,1,10,C^FH\^CI28^FDBXG\\5C&^FS^CI27 
^FT430,165^A0I,34,33^FB430,1,9,C^FH\^CI28^FD${firstName}5C&^FS^CI27
^FT430,122^A0I,34,33^FB430,1,9,C^FH\^CI28^FD${lastName}5C&^FS^CI27
^FT338,203^A0I,62,61^FH\^CI28^FD26${new Date(startTime)
            .getDate()
            .toString()
            .padStart(2, "0")} ${getMonthInText(
            new Date(startTime).getMonth()
          )} ${(new Date(startTime).getFullYear() - 2000).toString()}^FS^CI27
^FT279,87^A0I,34,33^FH\^CI28^FD${vpNumber}^FS^CI27
^FT420,166^A0I,152,152^FH\^CI28^FDV^FS^CI27
^FO205,17^GFA,549,1161,27,:Z64:eJx1lDFu4zAQRT8JEiCEFAoQws0GIVQZrvcAQ0DqUmgBCdvEd0gTuFqYUGXsKQxXBIs9g5vcIOniO2yTMvCOnGxHTsGCX59v5ksUkC3b5/e5Uiop+vxBBalKaV+QFpufJdgqpZhXxPl0eik2WGpRbEY75iWZQpVCVtLnox6pwNrLQ94lNr0ouiJKcfx6E2MhjlXEyilVW6ja1zcWA6yHFfz44gljb+CWwZjJGOCzp3mtEhuV7lu6+tZdDeS1oq5FW4P0CXaAdAEGUn41JOfFcRzSKXENGrTXj3QtQJ4YjEeeSvdG7oOL1STdJ0ZiClhOHOJO6R9Hf2+fNVGNlvyN7Xqsu9PrOPDBoTFOXlz/WUt+zc08F3meS7BLKPL1zPqO7ftlLhd2ZkJwXy4GrtgV47r7uxZvt8/iaGlQtlUXFu4+ZlYuQo6jcVlpQYtzIfkqyPQ7ZKXboy669jId8izdi+0rZSUZ8SfmWfpBbd/zLhzQNHlFjFx5iW/KbleQ2JSPkAdrChHyb2MsfbxALFzlGcaofzirbeU=:FFD6
^PQ1,0,1,Y
^XZ`;

          const zplBrisbane = `
            CT~~CD,~CC^~CT~
            ^XA
            ~TA000
            ~JSN
            ^LT0
            ^MNW
            ^MTT
            ^PON
            ^PMN
            ^LH0,0
            ^JMA
            ^PR4,4
            ~SD15
            ^JUS
            ^LRN
            ^CI27
            ^PA0,1,1,0
            ^XZ
            ^XA
            ^MMT
            ^PW432
            ^LL687
            ^LS0
            ^FT338,174^A0I,37,38^FB338,1,9,C^FH\^CI28^FD${firstName}^FS^CI27
            ^FT338,128^A0I,37,38^FB338,1,9,C^FH\^CI28^FD${lastName}^FS^CI27
            ^FT352,230^A0I,77,71^FH\^CI28^FD${new Date(startTime)
              .getDate()
              .toString()
              .padStart(2, "0")} ${getMonthInText(
              new Date(startTime).getMonth()
            )} ${(new Date(startTime).getFullYear() - 2000).toString()}^FS^CI27
            ^FT279,87^A0I,34,33^FH\^CI28^FD${vpNumber}^FS^CI27
            ^FT318,26^A0I,37,38^FH\^CI28^FDESCORT REQUIRED^FS^CI27
            ^PQ1,0,1,Y
            ^XZ
          `

          await browserPrint.print(airport?.name === 'Brisbane Airport' ? zplBrisbane : zpl);
        } else {
          console.error(
            "Error/s occurred while printing visitor pass - ",
            printerStatus.errors
          );
          setError(true);
        }
      } else {
        console.error(
          "Error/s occurred while printing visitor pass - no printers detected!"
        );
        setPrinterError(true);
        setTimeout(() => setPrinterError(false), 3000);
      }

      await api.printVisitorPassById(
        params.visitorPassRequestId ?? "",
        startTime,
        endTime
      );
      setPrintSuccess(true);
      await fetchVisitorPass(params.visitorPassRequestId ?? "");
      setTimeout(() => setPrintSuccess(false), 3000);
    } catch (error) {
      console.error(
        `Error occurred while printing visitor pass request with id ${params.visitorPassRequestId}`,
        error
      );
      setError(true);
    }
    setLoading(false);
  };

  const approveVisitorPassSponsor = async () => {
    try {
      setLoading(true);
      // Update VP first
      await updateVisitorPass();

      // Then approve
      await api.approveVisitorPassSponsorById(
        params.visitorPassRequestId ?? "",
        sponsorEmail,
        sponsorMobileNumber
      );

      await fetchVisitorPass(params.visitorPassRequestId ?? "");
      setSponsorApprovedSuccess(true);
      setTimeout(() => setSponsorApprovedSuccess(false), 3000);
    } catch (error) {
      console.error(
        "Error occurred while approving visitor pass sponsor - ",
        error
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="flex flex-col flex-1 md:pl-64">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="flex flex-row items-center text-2xl font-bold text-gray-900">
                    <UserAddIcon className="h-7 w-7 mr-1.5" />
                    Visitor Pass Request
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Approve or reject this visitor pass request
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    disabled={loading}
                    onClick={() => navigate("/visitor-pass-requests")}
                    type="button"
                    className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="flex flex-col max-w-screen-md mt-8">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-4">
                    {alertMessage && (
                      <div
                        className="flex flex-col shadow-sm cursor-pointer"
                        onClick={() => setAlertExpanded(!alertExpanded)}
                      >
                        <div
                          className={classNames(
                            "flex items-center gap-x-6 bg-amber-500 rounded-t-md px-6 py-2.5 sm:px-3.5 sm:before:flex-1",
                            alertExpanded ? "" : "rounded-b-md"
                          )}
                        >
                          <p className="inline-flex flex-row items-center text-sm leading-6 text-white">
                            <ExclamationIcon className="h-5 w-5 mr-1.5" />
                            <strong className="font-semibold ">Alert</strong>
                            <svg
                              viewBox="0 0 2 2"
                              className="mx-2 inline h-0.5 w-0.5 fill-current"
                              aria-hidden="true"
                            >
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                            Click to {alertExpanded ? "hide" : "show"}
                          </p>
                          <div className="flex justify-end flex-1">
                            <button
                              type="button"
                              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                            >
                              {alertExpanded ? (
                                <MinusIcon
                                  className="w-5 h-5 text-white"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="w-5 h-5 text-white"
                                  aria-hidden="true"
                                />
                              )}
                            </button>
                          </div>
                        </div>
                        {alertExpanded && (
                          <div className="p-4 text-sm whitespace-pre bg-amber-50 rounded-b-md">
                            {alertMessage}
                          </div>
                        )}
                      </div>
                    )}
                    {visitorBlocked && (
                      <div className="flex flex-col shadow-sm">
                        <div
                          className={classNames(
                            "flex items-center justify-center gap-x-6 bg-red-500 rounded-md px-6 py-2.5 sm:px-3.5"
                          )}
                        >
                          <p className="inline-flex flex-row items-center text-sm leading-6 text-white">
                            <ExclamationIcon className="h-5 w-5 mr-1.5" />
                            <strong className="font-semibold ">
                              Visitor Blocked
                            </strong>
                            <svg
                              viewBox="0 0 2 2"
                              className="mx-2 inline h-0.5 w-0.5 fill-current"
                              aria-hidden="true"
                            >
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                            Pass unable to be approved
                          </p>
                        </div>
                      </div>
                    )}

                    <div>
                      <div className="flex flex-row items-center text-sm">
                        <div>
                          <strong>Status:</strong>
                        </div>
                        {status === 1 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                            Requested
                          </div>
                        )}
                        {status === 2 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Approved
                          </div>
                        )}
                        {status === 3 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            Rejected
                          </div>
                        )}
                        {status === 4 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            Expired
                          </div>
                        )}
                        {status === 5 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-200 text-red-900">
                            Cancelled
                          </div>
                        )}
                        {status === 6 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-200 text-blue-900">
                            Printed
                          </div>
                        )}
                      </div>
                      <div className="flex flex-row items-center text-sm">
                        <div>
                          <strong>Sponsor Status:</strong>
                        </div>
                        {sponsorStatus === 1 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                            Pending
                          </div>
                        )}
                        {sponsorStatus === 2 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Approved
                          </div>
                        )}
                        {sponsorStatus === 3 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            Declined
                          </div>
                        )}
                        {sponsorStatus === 0 && (
                          <div className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-900">
                            Unknown
                          </div>
                        )}
                      </div>
                      <div className="flex flex-row items-center text-sm">
                        <div>
                          <strong>Airport:</strong>{" "}
                          <span>{airport?.name ?? "No Airport Specified"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="text-lg font-semibold underline">
                      Request Details
                    </div>
                    <div className="grid grid-cols-2 gap-y-6 gap-x-4">
                      <div>
                        <h3 className="mb-2 font-semibold">Face Photo</h3>
                        <img className="rounded-lg shadow" src={facePhoto} />
                      </div>
                      <div>
                        <h3 className="mb-2 font-semibold">ID Front Photo</h3>
                        <img className="rounded-lg shadow" src={idPhotoFront} />
                      </div>
                    </div>
                    <div>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email Address{showAsteriskIfEmpty(email)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              disabled={loading || status === 6 || status === 4}
                              value={email ?? ""}
                              onChange={(e) => setEmail(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First Name{showAsteriskIfEmpty(firstName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              disabled={loading || status === 6 || status === 4}
                              value={firstName ?? ""}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last Name{showAsteriskIfEmpty(lastName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              disabled={loading || status === 6 || status === 4}
                              value={lastName ?? ""}
                              onChange={(e) => setLastName(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Company{showAsteriskIfEmpty(company)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company"
                              id="company"
                              autoComplete="company"
                              disabled={loading || status === 6 || status === 4}
                              value={company ?? ""}
                              onChange={(e) => setCompany(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="mobile-number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Mobile Number{showAsteriskIfEmpty(mobileNumber)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="mobile-number"
                              id="mobile-number"
                              autoComplete="mobile-number"
                              disabled={loading || status === 6 || status === 4}
                              value={mobileNumber ?? ""}
                              onChange={(e) => setMobileNumber(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="col-span-6 ">
                          <div className="grid grid-cols-9 gap-6">
                            {ids.map((id, index) => (
                              <>
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="id-type"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    ID Type{showAsteriskIfEmpty(id.idType)}
                                  </label>
                                  <select
                                    id="id-type"
                                    name="id-type"
                                    autoComplete="id-type"
                                    onChange={(e) => {
                                      const newIds = [...ids];
                                      newIds[index].idType = e.target.value as
                                        | "Unknown"
                                        | "Passport"
                                        | "Driver's License";
                                      setIds(newIds);
                                    }}
                                    value={id.idType}
                                    className="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                                  >
                                    <option>Driver's License</option>
                                    <option>Passport</option>
                                  </select>
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="id-number"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    ID Number{showAsteriskIfEmpty(id.idNumber)}
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      id="id-number"
                                      name="id-number"
                                      type="text"
                                      autoComplete="new-id-number"
                                      onChange={(e) => {
                                        const newIds = [...ids];
                                        newIds[index].idNumber = e.target.value;
                                        setIds(newIds);
                                      }}
                                      required
                                      value={id.idNumber}
                                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div className="self-end col-span-6 sm:col-span-3">
                                  <button
                                    onClick={() => {
                                      const newIds = [...ids];
                                      newIds.splice(index, 1);
                                      setIds(newIds);
                                    }}
                                    className="flex items-center justify-center px-4 py-2 mt-auto text-sm font-medium text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                                  >
                                    <TrashIcon className="w-5 h-5 mr-1.5" />
                                    Remove ID
                                  </button>
                                </div>
                              </>
                            ))}

                            <div className="col-span-6 sm:col-span-3">
                              <button
                                onClick={() => {
                                  setIds([
                                    ...ids,
                                    { idType: "Unknown", idNumber: "" },
                                  ]);
                                }}
                                disabled={ids.length >= 2}
                                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50"
                              >
                                <PlusIcon className="w-5 h-5 mr-1.5" />
                                Add ID
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-6">
                          <label
                            htmlFor="operational-need"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Operational Need
                            {showAsteriskIfEmpty(operationalNeed)}
                          </label>
                          <div className="mt-2">
                            <textarea
                              rows={4}
                              name="operational-need"
                              id="operational-need"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                              disabled={loading || status === 6 || status === 4}
                              value={operationalNeed ?? ""}
                              onChange={(e) =>
                                setOperationalNeed(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        {rejectReason && (
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="rejectReason"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Reject Reason
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="rejectReason"
                                id="rejectReason"
                                autoComplete="rejectReason"
                                disabled={
                                  loading || status === 6 || status === 4
                                }
                                value={rejectReason ?? ""}
                                onChange={(e) =>
                                  setRejectReason(e.target.value)
                                }
                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                              />
                            </div>
                          </div>
                        )}

                        {cancelReason && (
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="cancelReason"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Cancel Reason
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="cancelReason"
                                id="cancelReason"
                                autoComplete="cancelReason"
                                disabled={
                                  loading || status === 6 || status === 4
                                }
                                value={cancelReason ?? ""}
                                onChange={(e) =>
                                  setCancelReason(e.target.value)
                                }
                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                              />
                            </div>
                          </div>
                        )}

                        <div className="col-span-6 sm:col-span-4">
                          <label
                            htmlFor="pass-duration"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Pass Duration{showAsteriskIfEmpty(rangeType)}
                          </label>
                          <select
                            id="pass-duration"
                            name="pass-duration"
                            autoComplete="pass-duration"
                            value={rangeType}
                            disabled
                            onChange={(e) => setRangeType(e.target.value)}
                            className="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                          >
                            <option>24 Hours</option>
                            <option>1 Week</option>
                            <option>1 Month</option>
                            <option>Custom</option>
                          </select>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="start-date"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Start Date{showAsteriskIfEmpty(startTime)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="datetime-local"
                              name="start-date"
                              id="start-date"
                              autoComplete="start-date"
                              disabled={loading || status === 6 || status === 4}
                              value={startTime ?? ""}
                              onChange={(e) => setStartTime(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="end-date"
                            className="block text-sm font-medium text-gray-700"
                          >
                            End Date{showAsteriskIfEmpty(endTime)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="datetime-local"
                              name="end-date"
                              id="end-date"
                              autoComplete="end-date"
                              disabled={
                                loading ||
                                status === 6 ||
                                rangeType !== "Custom" ||
                                status === 4
                              }
                              value={endTime ?? ""}
                              onChange={(e) => setEndTime(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="col-span-6 text-lg font-semibold underline">
                          Sponsor Details
                        </div>

                        {sponsorFacePhoto && (
                          <div className="sm:col-span-3">
                            <h3 className="mb-2 font-semibold">
                              Sponsor Face Photo
                            </h3>
                            <img
                              className="rounded-lg shadow"
                              src={sponsorFacePhoto}
                            />
                          </div>
                        )}

                        {sponsorIdPhotoFront && (
                          <div className="sm:col-span-3">
                            <h3 className="mb-2 font-semibold">
                              Sponsor ID Front Photo
                            </h3>
                            <img
                              className="rounded-lg shadow"
                              src={sponsorIdPhotoFront}
                            />
                          </div>
                        )}

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="sponsor-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Name{showAsteriskIfEmpty(sponsorName)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="sponsor-name"
                              id="sponsor-name"
                              autoComplete="sponsor-name"
                              disabled={loading || status === 6 || status === 4}
                              value={sponsorName ?? ""}
                              onChange={(e) => setSponsorName(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="sponsor-asic-id"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC ID{showAsteriskIfEmpty(sponsorAsicId)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="sponsor-asic-id"
                              id="sponsor-asic-id"
                              autoComplete="sponsor-asic-id"
                              disabled={loading || status === 6 || status === 4}
                              value={sponsorAsicId ?? ""}
                              onChange={(e) =>
                                setSponsorAsicId(e.target.value.toUpperCase())
                              }
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="col-span-3">
                          <label
                            htmlFor="sponsor-asic-expiry-month"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC Expiry Month
                            {showAsteriskIfEmpty(sponsorAsicExpiryMonth)}
                          </label>
                          <div className="mt-1">
                            <select
                              id="sponsor-asic-expiry-month"
                              name="sponsor-asic-expiry-month"
                              autoComplete="new-sponsor-asic-expiry-month"
                              value={sponsorAsicExpiryMonth}
                              onChange={(e) =>
                                setSponsorAsicExpiryMonth(e.target.value)
                              }
                              disabled={loading || status === 6 || status === 4}
                              required
                              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            >
                              <option value={1}>Jan</option>
                              <option value={2}>Feb</option>
                              <option value={3}>Mar</option>
                              <option value={4}>Apr</option>
                              <option value={5}>May</option>
                              <option value={6}>Jun</option>
                              <option value={7}>Jul</option>
                              <option value={8}>Aug</option>
                              <option value={9}>Sep</option>
                              <option value={10}>Oct</option>
                              <option value={11}>Nov</option>
                              <option value={12}>Dec</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-span-3">
                          <label
                            htmlFor="sponsor-asic-expiry-year"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC Expiry Year
                            {showAsteriskIfEmpty(sponsorAsicExpiryYear)}
                          </label>
                          <div className="mt-1">
                            <select
                              id="sponsor-asic-expiry-year"
                              name="sponsor-asic-expiry-year"
                              autoComplete="new-sponsor-asic-expiry-year"
                              value={sponsorAsicExpiryYear}
                              onChange={(e) =>
                                setSponsorAsicExpiryYear(e.target.value)
                              }
                              disabled={loading || status === 6 || status === 4}
                              required
                              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            >
                              <option>{new Date().getFullYear()}</option>
                              <option>{new Date().getFullYear() + 1}</option>
                              <option>{new Date().getFullYear() + 2}</option>
                            </select>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="sponsor-company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Company{showAsteriskIfEmpty(sponsorCompany)}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="sponsor-company"
                              id="sponsor-company"
                              autoComplete="sponsor-company"
                              disabled={loading || status === 6 || status === 4}
                              value={sponsorCompany ?? ""}
                              onChange={(e) =>
                                setSponsorCompany(e.target.value)
                              }
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="sponsor-email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Email
                            {showAsteriskIfEmpty(
                              !sponsorEmail && !sponsorMobileNumber
                                ? ""
                                : "non-empty"
                            )}
                          </label>
                          <div className="mt-1">
                            <input
                              type="email"
                              name="sponsor-email"
                              id="sponsor-email"
                              autoComplete="sponsor-email"
                              disabled={loading || status === 6 || status === 4}
                              value={sponsorEmail ?? ""}
                              onChange={(e) => setSponsorEmail(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="sponsor-mobile"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Mobile Number
                            {showAsteriskIfEmpty(
                              !sponsorEmail && !sponsorMobileNumber
                                ? ""
                                : "non-empty"
                            )}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="sponsor-mobile"
                              id="sponsor-mobile"
                              autoComplete="sponsor-mobile"
                              disabled={loading || status === 6 || status === 4}
                              value={sponsorMobileNumber ?? ""}
                              onChange={(e) =>
                                setSponsorMobileNumber(e.target.value)
                              }
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-6">
                          <button
                            disabled={
                              loading ||
                              status === 6 ||
                              status === 5 ||
                              status === 4 ||
                              status === 3 ||
                              sponsorStatus === 2 ||
                              visitorBlocked
                            }
                            onClick={() => approveVisitorPassSponsor()}
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-60"
                          >
                            <CheckIcon className="h-5 w-5 mr-1.5" />
                            Verify Sponsor
                          </button>
                        </div>
                      </div>
                    </div>

                    {!loading &&
                      (status === 6 ||
                        status === 5 ||
                        status === 4 ||
                        status === 3 ||
                        visitorBlocked) && (
                        <div className="block mb-2 text-sm font-medium text-red-500">
                          <div className="my-1">
                            Cannot verify a sponsor if the visitor pass is
                            printed, declined, cancelled, expired or the visitor
                            is blocked.
                          </div>
                        </div>
                      )}
                    {invalidForm && (
                      <div className="block mb-2 text-sm font-medium text-red-500">
                        <div className="my-1">
                          Please complete all required information (*) to update
                          your visitor pass request
                        </div>
                      </div>
                    )}
                  </div>

                  <VisitorPassLogs logs={visitorPassLogs} />

                  <div className="pt-5">
                    {sponsorStatus !== 2 && (
                      <div className="block mb-4 text-sm font-medium text-red-500">
                        <div className="">
                          Unable to approve visitor pass until the chosen
                          sponsor has verified and approved the pass.
                        </div>
                      </div>
                    )}
                    {visitorBlocked && (
                      <div className="block mb-4 text-sm font-medium text-red-500">
                        <div className="">
                          Unable to approve visitor pass if the visitor is
                          blocked.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full space-y-2 overflow-x-scroll sm:items-center sm:flex-row sm:space-x-2 sm:space-y-0 scrollbar scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-100">
                <button
                  onClick={() => navigate("/visitor-pass-requests")}
                  disabled={loading}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                >
                  <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                  Back
                </button>
                {!airportOfficerRole ? (
                  <>
                    <button
                      onClick={() => updateVisitorPass()}
                      disabled={loading || status === 6 || status === 4}
                      className="flex justify-center px-4 py-2 mr-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                    >
                      <SaveIcon className="h-5 w-5 mr-1.5" />
                      <span>Update</span>
                    </button>
                    <button
                      onClick={() => resendSponsorVerificationRequest()}
                      disabled={loading || status !== 1}
                      className="flex justify-center px-4 py-2 mr-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 whitespace-nowrap"
                      style={{ width: "max-content" }}
                    >
                      <MailIcon className="h-5 w-5 mr-1.5" />
                      <span>Request Sponsor</span>
                    </button>
                    <button
                      onClick={() => setOpenDeleteVisitorPassModal(true)}
                      disabled={loading || status === 6 || status === 4}
                      className="flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                    >
                      <TrashIcon className="h-5 w-5 mr-1.5" />
                      <span>Delete</span>
                    </button>
                    <button
                      disabled={
                        loading ||
                        status === 6 ||
                        status === 4 ||
                        sponsorStatus === 1 ||
                        sponsorStatus === 3 ||
                        visitorBlocked
                      }
                      onClick={() => approveVpRequest()}
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-60"
                    >
                      <ThumbUpIcon className="h-5 w-5 mr-1.5" />
                      Approve
                    </button>
                    <button
                      disabled={loading || status === 6 || status === 4}
                      onClick={() => setOpenRejectPassModal(true)}
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-900 border border-transparent rounded-md shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black disabled:opacity-60"
                    >
                      <ThumbDownIcon className="h-5 w-5 mr-1.5" />
                      Reject
                    </button>
                    <button
                      disabled={loading || status === 6 || status === 4}
                      onClick={() => setOpenCancelPassModal(true)}
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                    >
                      <XIcon className="h-5 w-5 mr-1.5" />
                      Cancel
                    </button>
                    <button
                      disabled={
                        loading ||
                        status === 4 ||
                        (status !== 2 && status !== 6)
                      }
                      onClick={() => setOpenPrintPassModal(true)}
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-60"
                    >
                      <PrinterIcon className="h-5 w-5 mr-1.5" />
                      {status === 6 ? "Reprint" : "Print"}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => resendSponsorVerificationRequest()}
                      disabled={loading || status !== 1}
                      className="flex justify-center px-4 py-2 mr-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 whitespace-nowrap"
                      style={{ width: "max-content" }}
                    >
                      <MailIcon className="h-5 w-5 mr-1.5" />
                      <span>Request Sponsor</span>
                    </button>
                    {status !== 6 && status !== 2 && status !== 3 && (
                      <button
                        disabled={
                          loading ||
                          status === 6 ||
                          status === 4 ||
                          sponsorStatus === 1 ||
                          sponsorStatus === 3 ||
                          visitorBlocked
                        }
                        onClick={() => approveVpRequest()}
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-60"
                      >
                        <ThumbUpIcon className="h-5 w-5 mr-1.5" />
                        Approve
                      </button>
                    )}

                    {status !== 6 && status !== 2 && status !== 3 && (
                      <button
                        disabled={loading || status === 6 || status === 4}
                        onClick={() => setOpenRejectPassModal(true)}
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-900 border border-transparent rounded-md shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black disabled:opacity-60"
                      >
                        <ThumbDownIcon className="h-5 w-5 mr-1.5" />
                        Reject
                      </button>
                    )}
                    {status !== 1 && status !== 3 && (
                      <button
                        disabled={loading || status === 6 || status === 4}
                        onClick={() => setOpenCancelPassModal(true)}
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                      >
                        <XIcon className="h-5 w-5 mr-1.5" />
                        Cancel
                      </button>
                    )}

                    {(status === 2 || status === 6) && (
                      <button
                        disabled={loading || (status !== 2 && status !== 6)}
                        onClick={() => setOpenPrintPassModal(true)}
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-60"
                      >
                        <PrinterIcon className="h-5 w-5 mr-1.5" />
                        {status === 6 ? "Reprint" : "Print"}
                      </button>
                    )}
                  </>
                )}

                <Transition.Root show={openRejectPassModal} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={setOpenRejectPassModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                            <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                              <button
                                type="button"
                                className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                                onClick={() => setOpenRejectPassModal(false)}
                              >
                                <span className="sr-only">Close</span>
                                <XIcon className="w-6 h-6" aria-hidden="true" />
                              </button>
                            </div>
                            <div className="">
                              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                <ExclamationIcon
                                  className="w-6 h-6 text-red-600"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="mt-3 text-center sm:text-left">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  <span>Reject Visitor Pass</span>
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Are you sure you want to reject this visitor
                                    pass request?
                                  </p>
                                </div>
                                <div className="mt-2">
                                  <label
                                    htmlFor="reason"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Provide a reason
                                  </label>
                                  <div className="mt-1">
                                    <textarea
                                      rows={4}
                                      name="reason"
                                      id="reason"
                                      onChange={(e) =>
                                        setRejectReason(e.target.value)
                                      }
                                      value={rejectReason}
                                      placeholder={"Reason"}
                                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!rejectReason && (
                              <div className="mt-2 text-sm font-semibold text-red-600">
                                Please provide a reason for rejecting the
                                visitor pass
                              </div>
                            )}

                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                              <button
                                type="button"
                                disabled={loading || !rejectReason}
                                className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-60"
                                onClick={() => rejectVpRequest()}
                              >
                                Reject
                              </button>
                              <button
                                type="button"
                                className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:w-auto sm:text-sm"
                                onClick={() => setOpenRejectPassModal(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
                <Transition.Root show={openCancelPassModal} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={setOpenCancelPassModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                            <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                              <button
                                type="button"
                                className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                                onClick={() => setOpenCancelPassModal(false)}
                              >
                                <span className="sr-only">Close</span>
                                <XIcon className="w-6 h-6" aria-hidden="true" />
                              </button>
                            </div>
                            <div className="">
                              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                <ExclamationIcon
                                  className="w-6 h-6 text-red-600"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="mt-3 text-center sm:text-left">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  <span>Cancel Visitor Pass</span>
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Are you sure you want to cancel this visitor
                                    pass request?
                                  </p>
                                </div>
                                <div className="mt-2">
                                  <label
                                    htmlFor="reason"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Provide a reason
                                  </label>
                                  <div className="mt-1">
                                    <textarea
                                      rows={4}
                                      name="reason"
                                      id="reason"
                                      onChange={(e) =>
                                        setCancelReason(e.target.value)
                                      }
                                      value={cancelReason}
                                      placeholder={"Reason"}
                                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!cancelReason && (
                              <div className="mt-2 text-sm font-semibold text-red-600">
                                Please provide a reason for cancelling the
                                visitor pass
                              </div>
                            )}

                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                              <button
                                type="button"
                                disabled={loading || !cancelReason}
                                className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-60"
                                onClick={() => cancelVpRequest()}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:w-auto sm:text-sm"
                                onClick={() => setOpenCancelPassModal(false)}
                              >
                                Back
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
                <Transition.Root show={openPrintPassModal} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={setOpenPrintPassModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                            <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                              <button
                                type="button"
                                className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                                onClick={() => setOpenPrintPassModal(false)}
                              >
                                <span className="sr-only">Close</span>
                                <XIcon className="w-6 h-6" aria-hidden="true" />
                              </button>
                            </div>
                            <div className="">
                              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                <PrinterIcon
                                  className="w-6 h-6 text-blue-600"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="mt-3 text-center sm:text-left">
                                <Dialog.Title
                                  as="h3"
                                  className="text-xl font-medium leading-6 text-gray-900"
                                >
                                  <span>Print Visitor Pass</span>
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Are you sure you want to print this visitor
                                    pass request? Doing so will automatically
                                    start the visitor pass at the current time
                                    in this timezone (unless the checkbox below
                                    is ticked).{" "}
                                    <strong>
                                      Once printed, the pass cannot be modified.
                                    </strong>
                                  </p>
                                </div>
                                {status !== 6 && (
                                  <div className="mt-4">
                                    <div className="sm:col-span-3">
                                      <Switch.Group
                                        as="div"
                                        className="flex items-center"
                                      >
                                        <Switch
                                          checked={startPassAtOriginalStartTime}
                                          onChange={
                                            setStartPassAtOriginalStartTime
                                          }
                                          className={classNames(
                                            startPassAtOriginalStartTime
                                              ? "bg-blue-500"
                                              : "bg-gray-200",
                                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                          )}
                                        >
                                          <span
                                            aria-hidden="true"
                                            className={classNames(
                                              startPassAtOriginalStartTime
                                                ? "translate-x-5"
                                                : "translate-x-0",
                                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                            )}
                                          />
                                        </Switch>
                                        <Switch.Label
                                          as="span"
                                          className="ml-3 text-sm"
                                        >
                                          <span className="font-medium text-gray-900">
                                            Revert to original pass start time
                                          </span>
                                        </Switch.Label>
                                      </Switch.Group>
                                    </div>
                                  </div>
                                )}
                                <div className="mt-4 text-sm text-gray-500">
                                  This pass will start on{" "}
                                  {moment(startTime).format(
                                    "dddd, MMMM Do YYYY, h:mm a z"
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                              <button
                                type="button"
                                disabled={
                                  loading ||
                                  (status !== 1 && status !== 2 && status !== 6)
                                }
                                className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-60"
                                onClick={() => printVpRequest()}
                              >
                                Print
                              </button>
                              <button
                                type="button"
                                className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:w-auto sm:text-sm"
                                onClick={() => setOpenPrintPassModal(false)}
                              >
                                Back
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={updateSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated visitor pass request
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please update the status accordingly
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setUpdateSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={approveSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ThumbUpIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully approved pass request
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setApproveSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={rejectSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ThumbDownIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully rejected pass request
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setRejectSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={cancelSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully cancelled pass request
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setCancelSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={printSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <PrinterIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully printed pass request
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setPrintSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={sponsorApprovedSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully approved pass sponsor
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      The pass request can now be approved
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSponsorApprovedSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={sponsorChangedSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully changed pass sponsor
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please wait for the sponsor to verify and approve the pass
                      request
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSponsorChangedSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={sponsorResendSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully resent sponsor verification request
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please wait for the sponsor to verify and approve the pass
                      request
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSponsorResendSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="w-6 h-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={printerError}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <PrinterIcon
                      className="w-6 h-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      No available printers found
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please connect a compatible printer and try again
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setPrinterError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      <DeleteModal
        description="Deleting a visitor pass is permanent. You will not be able to recover it once deleted"
        open={openDeleteVisitorPassModal}
        setOpen={setOpenDeleteVisitorPassModal}
        title={"Are you sure you want to delete this pass?"}
        action={() => deleteVisitorPass()}
      />
    </>
  );
}
