import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom"
import AddAirport from "./pages/AirportManagement/AddAirport/AddAirport"
import AirportManagement from "./pages/AirportManagement/AirportManagement"
import EditAirport from "./pages/AirportManagement/EditAirport/EditAirport"
import SignIn from "./pages/Auth/SignIn/SignIn"
import SignOut from "./pages/Auth/SignOut/SignOut"
import AddUser from "./pages/UserManagement/AddUser/AddUser"
import EditUser from "./pages/UserManagement/EditUser/EditUser"
import UserManagement from "./pages/UserManagement/UserManagement"
import AddVisitor from "./pages/VisitorManagement/AddVisitor/AddVisitor"
import EditVisitor from "./pages/VisitorManagement/EditVisitor/EditVisitor"
import VisitorManagement from "./pages/VisitorManagement/VisitorManagement"
import { CreateVisitorPass } from "./pages/VisitorPassRequests/CreateVisitorPass/CreateVisitorPass"
import VisitorPassRequest from "./pages/VisitorPassRequests/VisitorPassRequest/VisitorPassRequest"
import VisitorPassRequests from "./pages/VisitorPassRequests/VisitorPassRequests"
import AddSponsor from "./pages/SponsorManagement/AddSponsor/AddSponsor"
import SponsorManagement from "./pages/SponsorManagement/SponsorManagement"
import EditSponsor from "./pages/SponsorManagement/EditSponsor/EditSponsor"
import { AuthProvider, RequireAuth } from "./utils/auth/auth"

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signout" element={<SignOut />} />
          <Route
            path="/user-management"
            element={
              <RequireAuth>
                <UserManagement />
              </RequireAuth>
            }
          />
          <Route
            path="/user-management/add"
            element={
              <RequireAuth>
                <AddUser />
              </RequireAuth>
            }
          />
          <Route
            path="/user-management/edit/:userId"
            element={
              <RequireAuth>
                <EditUser />
              </RequireAuth>
            }
          />
          <Route
            path="/visitor-management"
            element={
              <RequireAuth>
                <VisitorManagement />
              </RequireAuth>
            }
          />
          <Route
            path="/visitor-management/edit/:visitorId"
            element={
              <RequireAuth>
                <EditVisitor />
              </RequireAuth>
            }
          />
          <Route
            path="/visitor-management/add"
            element={
              <RequireAuth>
                <AddVisitor />
              </RequireAuth>
            }
          />
          <Route
            path="/visitor-pass-requests"
            element={
              <RequireAuth>
                <VisitorPassRequests />
              </RequireAuth>
            }
          />
          <Route
            path="/visitor-pass-requests/add"
            element={
              <RequireAuth>
                <CreateVisitorPass />
              </RequireAuth>
            }
          />
          <Route
            path="/visitor-pass-requests/:visitorPassRequestId"
            element={
              <RequireAuth>
                <VisitorPassRequest />
              </RequireAuth>
            }
          />
          <Route
            path="/visitor-pass-requests/visitor/:visitorId"
            element={
              <RequireAuth>
                <VisitorPassRequests />
              </RequireAuth>
            }
          />
          <Route
            path="/visitor-pass-requests/airport/:airportId"
            element={
              <RequireAuth>
                <VisitorPassRequests />
              </RequireAuth>
            }
          />
          <Route
            path="/airport-management"
            element={
              <RequireAuth>
                <AirportManagement />
              </RequireAuth>
            }
          />
          <Route
            path="/airport-management/add"
            element={
              <RequireAuth>
                <AddAirport />
              </RequireAuth>
            }
          />
          <Route
            path="/airport-management/edit/:airportId"
            element={
              <RequireAuth>
                <EditAirport />
              </RequireAuth>
            }
          />
          <Route
            path="/sponsor-management"
            element={
              <RequireAuth>
                <SponsorManagement />
              </RequireAuth>
            }
          />
          <Route
            path="/sponsor-management/add"
            element={
              <RequireAuth>
                <AddSponsor />
              </RequireAuth>
            }
          />
          <Route
            path="/sponsor-management/edit/:sponsorId"
            element={
              <RequireAuth>
                <EditSponsor />
              </RequireAuth>
            }
          />
          <Route path="/" element={<Navigate to="/signin" />} />
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default App
