import { Transition } from "@headlessui/react"
import { ArrowLeftIcon, CheckCircleIcon, XIcon } from "@heroicons/react/outline"
import { PencilIcon, TrashIcon } from "@heroicons/react/solid"
import { Fragment, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import DeleteModal from "../../../components/DeleteModal/DeleteModal"
import Sidebar from "../../../components/Sidebar/Sidebar"
import api from "../../../utils/helpers/api"
import { UpdateSponsorRequest } from "../../../utils/types"

export default function EditSponsor() {
  const navigate = useNavigate()
  const params = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
  const [name, setName] = useState<string>("")
  const [timezone, setTimezone] = useState<string>("")
  const [openDeleteSponsorModal, setOpenDeleteSponsorModal] =
    useState<boolean>(false)

  const [sponsorName, setSponsorName] = useState<string>("")
  const [sponsorAsicId, setSponsorAsicId] = useState<string>("")
  const [sponsorAsicExpiryMonth, setSponsorAsicExpiryMonth] = useState<string>(
    (new Date().getMonth() + 1).toString()
  )
  const [sponsorAsicExpiryYear, setSponsorAsicExpiryYear] = useState<string>(
    (new Date().getFullYear() + 1).toString()
  )
  const [sponsorCompany, setSponsorCompany] = useState<string>("")
  const [sponsorEmail, setSponsorEmail] = useState<string>("")
  const [sponsorMobileNumber, setSponsorMobileNumber] = useState<string>("")

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>
    }
  }

  useEffect(() => {
    // Get sponsor from database
    const sponsorId = params.sponsorId
    if (sponsorId) {
      fetchSponsor(sponsorId)
    } else {
      setError(true)
    }
  }, [])

  const fetchSponsor = async (sponsorId: string) => {
    api
      .getSponsorById(sponsorId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          const sponsor = res.data
          setSponsorName(sponsor.sponsorName)
          setSponsorAsicId(sponsor.asicId)
          setSponsorAsicExpiryMonth(sponsor.expiryMonth)
          setSponsorAsicExpiryYear(sponsor.expiryYear)
          setSponsorCompany(sponsor.sponsorCompany)
          setSponsorEmail(sponsor.sponsorEmail)
          setSponsorMobileNumber(sponsor.sponsorMobile)
        }
      })
      .catch((error) => {
        console.error(
          `Error occurred while fetching sponsor with id ${sponsorId}`,
          error
        )
      })
      .finally(() => setLoading(false))
  }

  const updateSponsor = async () => {
    setLoading(true)
    try {
      if (params.sponsorId) {
        const updatedSponsor = {
          asicId: sponsorAsicId,
          sponsorName,
          sponsorCompany,
          sponsorEmail,
          sponsorMobile: sponsorMobileNumber,
          expiryMonth: sponsorAsicExpiryMonth,
          expiryYear: sponsorAsicExpiryYear,
        } as UpdateSponsorRequest

        await api.updateSponsorById(params.sponsorId, updatedSponsor)
        setSuccess(true)
        setTimeout(() => navigate("/sponsor-management"), 3000)
      } else {
        console.error(
          "Error occurred while updating sponsor - couldn't find the sponsor's id from the URL parameters"
        )
        setError(true)
      }
    } catch (error) {
      console.error("Error occurred while updating sponsor - ", error)
      setError(true)
    }
    setLoading(false)
  }

  const deleteSponsor = async () => {
    setLoading(true)
    setOpenDeleteSponsorModal(false)
    try {
      if (params.sponsorId) {
        await api.deleteSponsor(params.sponsorId)
        setDeleteSuccess(true)
        setTimeout(() => navigate("/sponsor-management"), 3000)
      } else {
        console.error(
          "Error occurred while deleting sponsor - couldn't find the sponsor's id from the URL parameters"
        )
        setError(true)
      }
    } catch (error) {
      console.error("Error occurred while deleting sponsor - ", error)
      setError(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit Sponsor {name && <em className="ml-1">({name})</em>}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the details of an sponsor or delete it from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="pt-4">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Name{showAsteriskIfEmpty(sponsorName)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="sponsor-name"
                              name="sponsor-name"
                              type="text"
                              autoComplete="new-sponsor-name"
                              onChange={(e) => setSponsorName(e.target.value)}
                              required
                              value={sponsorName}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-asic-id"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC ID
                            {showAsteriskIfEmpty(sponsorAsicId)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="sponsor-asic-id"
                              name="sponsor-asic-id"
                              type="text"
                              autoComplete="new-sponsor-asic-id"
                              onChange={(e) =>
                                setSponsorAsicId(e.target.value.toUpperCase())
                              }
                              required
                              value={sponsorAsicId}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-asic-expiry-month"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC Expiry Month
                            {showAsteriskIfEmpty(sponsorAsicExpiryMonth)}
                          </label>
                          <div className="mt-1">
                            <select
                              id="sponsor-asic-expiry-month"
                              name="sponsor-asic-expiry-month"
                              autoComplete="new-sponsor-asic-expiry-month"
                              value={sponsorAsicExpiryMonth}
                              onChange={(e) =>
                                setSponsorAsicExpiryMonth(e.target.value)
                              }
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            >
                              <option value={1}>Jan</option>
                              <option value={2}>Feb</option>
                              <option value={3}>Mar</option>
                              <option value={4}>Apr</option>
                              <option value={5}>May</option>
                              <option value={6}>Jun</option>
                              <option value={7}>Jul</option>
                              <option value={8}>Aug</option>
                              <option value={9}>Sep</option>
                              <option value={10}>Oct</option>
                              <option value={11}>Nov</option>
                              <option value={12}>Dec</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-asic-expiry-year"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC Expiry Year
                            {showAsteriskIfEmpty(sponsorAsicExpiryYear)}
                          </label>
                          <div className="mt-1">
                            <select
                              id="sponsor-asic-expiry-year"
                              name="sponsor-asic-expiry-year"
                              autoComplete="new-sponsor-asic-expiry-year"
                              value={sponsorAsicExpiryYear}
                              onChange={(e) =>
                                setSponsorAsicExpiryYear(e.target.value)
                              }
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            >
                              <option>{new Date().getFullYear()}</option>
                              <option>{new Date().getFullYear() + 1}</option>
                              <option>{new Date().getFullYear() + 2}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Company
                            {showAsteriskIfEmpty(sponsorCompany)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="sponsor-company"
                              name="sponsor-company"
                              type="text"
                              autoComplete="new-sponsor-company"
                              onChange={(e) =>
                                setSponsorCompany(e.target.value)
                              }
                              required
                              value={sponsorCompany}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Email
                            {showAsteriskIfEmpty(
                              sponsorMobileNumber || sponsorEmail
                                ? "non-empty"
                                : ""
                            )}
                          </label>
                          <div className="mt-1">
                            <input
                              id="sponsor-email"
                              name="sponsor-email"
                              type="email"
                              autoComplete="new-sponsor-email"
                              onChange={(e) => setSponsorEmail(e.target.value)}
                              required
                              value={sponsorEmail}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-mobile"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Mobile Number
                            {showAsteriskIfEmpty(
                              sponsorMobileNumber || sponsorEmail
                                ? "non-empty"
                                : ""
                            )}
                          </label>
                          <div className="mt-1">
                            <input
                              id="sponsor-mobile"
                              name="sponsor-mobile"
                              type="text"
                              autoComplete="new-sponsor-mobile"
                              onChange={(e) =>
                                setSponsorMobileNumber(e.target.value)
                              }
                              required
                              value={sponsorMobileNumber}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Delete Sponsor
                        </h3>
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          disabled={loading}
                          onClick={() => setOpenDeleteSponsorModal(true)}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 disabled:opacity-50"
                        >
                          <TrashIcon className="h-4 w-4 mr-1" />
                          Delete Sponsor
                        </button>
                      </div>
                      <DeleteModal
                        description="Deleting a sponsor is permanent. No visitor passes will be able to be created for this sponsor and it will be erased from Visitor Pass' databases."
                        open={openDeleteSponsorModal}
                        setOpen={setOpenDeleteSponsorModal}
                        title={"Are you sure you want to delete this sponsor?"}
                        action={() => deleteSponsor()}
                        actionButtonText={"Delete"}
                      />
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/sponsor-management")}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => updateSponsor()}
                        disabled={
                          loading ||
                          !sponsorAsicId ||
                          !sponsorName ||
                          !sponsorCompany ||
                          !sponsorEmail ||
                          !sponsorMobileNumber ||
                          !sponsorAsicExpiryMonth ||
                          !sponsorAsicExpiryYear
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      {(!sponsorAsicId ||
                        !sponsorName ||
                        !sponsorCompany ||
                        !sponsorEmail ||
                        !sponsorMobileNumber ||
                        !sponsorAsicExpiryMonth ||
                        !sponsorAsicExpiryYear) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to update sponsor
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated sponsor
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={deleteSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully deleted sponsor
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setDeleteSuccess(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
