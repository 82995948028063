import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  RefreshIcon,
  HandIcon,
} from "@heroicons/react/outline"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import SearchForm from "../../components/SearchForm/SearchForm"
import Sidebar from "../../components/Sidebar/Sidebar"
import SortButton from "../../components/SortButton/SortButton"
import api from "../../utils/helpers/api"
import { useAuthToken } from "../../hooks/useAuthToken"
import { usePageInitialization } from "../../hooks/usePageInitialization"

export default function SponsorManagement() {
  const { isInitiated } = usePageInitialization()
  const navigate = useNavigate()
  const [sponsors, setSponsors] = useState<any[]>([])
  const [rowsPerPage, setRowsPerPage] = useState<number>(25)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("DESC")
  const [sortField, setSortField] = useState<string>("createdAt")
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [searchField, setSearchField] = useState<string>("")

  useAuthToken()

  useEffect(() => {
    if (!isInitiated) return
    fetchSponsors()
  }, [pageNumber, searchQuery, searchField, sortField, sortOrder, isInitiated])

  const fetchSponsors = async () => {
    setLoading(true)
    try {
      const users = await api.getSponsors(
        pageNumber,
        rowsPerPage,
        searchQuery,
        searchField,
        sortOrder,
        sortField
      )

      console.log("users", users)
      if (users.status === 200 && users.data.data) {
        setTotalCount(users.data.totalCount)
        setSponsors(users.data.data)
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} sponsors of page ${pageNumber} - `,
        error
      )
    }
    setLoading(false)
  }

  const createPageNumbers = () => {
    const pageNumbers = []
    for (let i = 1; i <= Math.ceil(totalCount / rowsPerPage); i++) {
      pageNumbers.push(
        <button
          onClick={() => setPageNumber(i)}
          aria-current="page"
          disabled={loading}
          className={
            pageNumber === i
              ? "z-10 bg-amber-50 border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
              : "border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
          }
        >
          {i}
        </button>
      )
    }
    return pageNumbers
  }

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField)
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC")
      else setSortField("")
    } else {
      setSortOrder("DESC")
    }
  }

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <HandIcon className="h-6 w-6 mr-1.5" />
                    Sponsor Management
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the sponsors in Visitor Pass including their
                    details.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none items-center flex flex-row">
                  <button
                    onClick={() => navigate("/sponsor-management/add")}
                    type="button"
                    disabled={loading}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-amber-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-amber-500"
                  >
                    <PlusIcon className="h-5 w-5 mr-1.5" />
                    Add sponsor
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchSponsors()}
                    className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-stone-600"
                  >
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col">
                <SearchForm
                  searchOptions={[
                    { name: "All", value: "all" },
                    { name: "Name", value: "name" },
                    { name: "Code", value: "code" },
                    { name: "Timezone", value: "timezone" },
                  ]}
                  updateSearchQueryAndField={(
                    newSearchQuery,
                    newSearchField
                  ) => {
                    setSearchQuery(newSearchQuery)
                    setSearchField(newSearchField)
                  }}
                />
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                ASIC ID
                                <SortButton
                                  active={sortField === "asicId"}
                                  sortOrder={sortOrder}
                                  sortField={"asicId"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Name
                                <SortButton
                                  active={sortField === "sponsorName"}
                                  sortOrder={sortOrder}
                                  sortField={"sponsorName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Company
                                <SortButton
                                  active={sortField === "sponsorCompany"}
                                  sortOrder={sortOrder}
                                  sortField={"sponsorCompany"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Email
                                <SortButton
                                  active={sortField === "sponsorEmail"}
                                  sortOrder={sortOrder}
                                  sortField={"sponsorEmail"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Mobile
                                <SortButton
                                  active={sortField === "sponsorMobile"}
                                  sortOrder={sortOrder}
                                  sortField={"sponsorMobile"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Expiry Month
                                <SortButton
                                  active={sortField === "expiryMonth"}
                                  sortOrder={sortOrder}
                                  sortField={"expiryMonth"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Expiry Year
                                <SortButton
                                  active={sortField === "expiryYear"}
                                  sortOrder={sortOrder}
                                  sortField={"expiryYear"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {sponsors.map((sponsor) => (
                            <tr
                              key={sponsor.id}
                              className="hover:bg-slate-50 cursor-pointer"
                              onClick={() =>
                                navigate(
                                  "/sponsor-management/edit/" + sponsor.id
                                )
                              }
                            >
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex flex-row items-center space-x-2">
                                <div>{sponsor.asicId}</div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {sponsor.sponsorName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {sponsor.sponsorCompany}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {sponsor.sponsorEmail}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {sponsor.sponsorMobile}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {sponsor.expiryMonth}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {sponsor.expiryYear}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link
                                  to={"/sponsor-management/edit/" + sponsor.id}
                                >
                                  <div className="text-amber-600 hover:text-amber-900">
                                    Edit
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="py-3 flex items-center justify-between border-t border-gray-200">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <p className="text-sm text-gray-700">
                              Showing{" "}
                              <span className="font-medium">
                                {rowsPerPage * pageNumber - rowsPerPage + 1}
                              </span>{" "}
                              to{" "}
                              <span className="font-medium">
                                {rowsPerPage * pageNumber > totalCount
                                  ? totalCount
                                  : rowsPerPage * pageNumber}
                              </span>{" "}
                              of{" "}
                              <span className="font-medium">{totalCount}</span>{" "}
                              results
                            </p>
                          ) : (
                            <p className="text-sm text-gray-700">
                              Showing 0 results
                            </p>
                          )}
                        </div>
                        <div>
                          <nav
                            className="bg-white relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination"
                          >
                            <button
                              onClick={() => setPageNumber(pageNumber - 1)}
                              disabled={pageNumber === 1 || loading}
                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white"
                            >
                              <span className="sr-only">Previous</span>
                              <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "border-gray-300 text-gray-500 hover:bg-gray-50" */}
                            {createPageNumbers()}
                            <button
                              onClick={() => setPageNumber(pageNumber + 1)}
                              disabled={
                                rowsPerPage * pageNumber > totalCount || loading
                              }
                              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white"
                            >
                              <span className="sr-only">Next</span>
                              <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
