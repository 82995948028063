import { Transition } from "@headlessui/react"
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  GlobeIcon,
  XIcon,
} from "@heroicons/react/outline"
import { Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"
import Sidebar from "../../../components/Sidebar/Sidebar"
import api from "../../../utils/helpers/api"

export default function AddSponsor() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [sponsorName, setSponsorName] = useState<string>("")
  const [sponsorAsicId, setSponsorAsicId] = useState<string>("")
  const [sponsorAsicExpiryMonth, setSponsorAsicExpiryMonth] = useState<string>(
    (new Date().getMonth() + 1).toString()
  )
  const [sponsorAsicExpiryYear, setSponsorAsicExpiryYear] = useState<string>(
    (new Date().getFullYear() + 1).toString()
  )
  const [sponsorCompany, setSponsorCompany] = useState<string>("")
  const [sponsorEmail, setSponsorEmail] = useState<string>("")
  const [sponsorMobileNumber, setSponsorMobileNumber] = useState<string>("")

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>
    }
  }

  const addSponsor = async () => {
    setLoading(true)
    try {
      await api.createSponsor({
        asicId: sponsorAsicId,
        sponsorName,
        sponsorCompany,
        sponsorEmail,
        sponsorMobile: sponsorMobileNumber,
        expiryMonth: sponsorAsicExpiryMonth,
        expiryYear: sponsorAsicExpiryYear,
      })
      setSuccess(true)
      setTimeout(() => navigate("/sponsor-management"), 3000)
    } catch (error) {
      console.error("Error occurred while creating sponsor - ", error)
      setError(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <GlobeIcon className="h-7 w-7 mr-1.5" />
                    Add Sponsor
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Create a new sponsor
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8">
                  <div className="space-y-8">
                    <div>
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Name{showAsteriskIfEmpty(sponsorName)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="sponsor-name"
                              name="sponsor-name"
                              type="text"
                              autoComplete="new-sponsor-name"
                              onChange={(e) => setSponsorName(e.target.value)}
                              required
                              value={sponsorName}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-asic-id"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC ID
                            {showAsteriskIfEmpty(sponsorAsicId)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="sponsor-asic-id"
                              name="sponsor-asic-id"
                              type="text"
                              autoComplete="new-sponsor-asic-id"
                              onChange={(e) =>
                                setSponsorAsicId(e.target.value.toUpperCase())
                              }
                              required
                              value={sponsorAsicId}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-asic-expiry-month"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC Expiry Month
                            {showAsteriskIfEmpty(sponsorAsicExpiryMonth)}
                          </label>
                          <div className="mt-1">
                            <select
                              id="sponsor-asic-expiry-month"
                              name="sponsor-asic-expiry-month"
                              autoComplete="new-sponsor-asic-expiry-month"
                              value={sponsorAsicExpiryMonth}
                              onChange={(e) =>
                                setSponsorAsicExpiryMonth(e.target.value)
                              }
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            >
                              <option value={1}>Jan</option>
                              <option value={2}>Feb</option>
                              <option value={3}>Mar</option>
                              <option value={4}>Apr</option>
                              <option value={5}>May</option>
                              <option value={6}>Jun</option>
                              <option value={7}>Jul</option>
                              <option value={8}>Aug</option>
                              <option value={9}>Sep</option>
                              <option value={10}>Oct</option>
                              <option value={11}>Nov</option>
                              <option value={12}>Dec</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-asic-expiry-year"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor ASIC Expiry Year
                            {showAsteriskIfEmpty(sponsorAsicExpiryYear)}
                          </label>
                          <div className="mt-1">
                            <select
                              id="sponsor-asic-expiry-year"
                              name="sponsor-asic-expiry-year"
                              autoComplete="new-sponsor-asic-expiry-year"
                              value={sponsorAsicExpiryYear}
                              onChange={(e) =>
                                setSponsorAsicExpiryYear(e.target.value)
                              }
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            >
                              <option>{new Date().getFullYear()}</option>
                              <option>{new Date().getFullYear() + 1}</option>
                              <option>{new Date().getFullYear() + 2}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Company
                            {showAsteriskIfEmpty(sponsorCompany)}
                          </label>
                          <div className="mt-1">
                            <input
                              id="sponsor-company"
                              name="sponsor-company"
                              type="text"
                              autoComplete="new-sponsor-company"
                              onChange={(e) =>
                                setSponsorCompany(e.target.value)
                              }
                              required
                              value={sponsorCompany}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Email
                            {showAsteriskIfEmpty(
                              sponsorMobileNumber || sponsorEmail
                                ? "non-empty"
                                : ""
                            )}
                          </label>
                          <div className="mt-1">
                            <input
                              id="sponsor-email"
                              name="sponsor-email"
                              type="email"
                              autoComplete="new-sponsor-email"
                              onChange={(e) => setSponsorEmail(e.target.value)}
                              required
                              value={sponsorEmail}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="sponsor-mobile"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sponsor Mobile Number
                            {showAsteriskIfEmpty(
                              sponsorMobileNumber || sponsorEmail
                                ? "non-empty"
                                : ""
                            )}
                          </label>
                          <div className="mt-1">
                            <input
                              id="sponsor-mobile"
                              name="sponsor-mobile"
                              type="text"
                              autoComplete="new-sponsor-mobile"
                              onChange={(e) =>
                                setSponsorMobileNumber(e.target.value)
                              }
                              required
                              value={sponsorMobileNumber}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/sponsor-management")}
                        type="button"
                        disabled={loading}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => addSponsor()}
                        disabled={
                          loading ||
                          !sponsorAsicId ||
                          !sponsorName ||
                          !sponsorCompany ||
                          !sponsorEmail ||
                          !sponsorMobileNumber ||
                          !sponsorAsicExpiryMonth ||
                          !sponsorAsicExpiryYear
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully created sponsor
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Returning to sponsor management portal...
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
